$fc-footer-height: 44px;

html, body {
    font-family: 'Open Sans',Arial,Helvetica,sans-serif;
}
html {
    position: relative;
    min-height: 100%;
}
body {
    margin-bottom: $fc-footer-height;
}

.fullscreen-background {
    background: url("../images/nyc-night.jpg") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

div.glass-pane {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    min-height: 42vh;  /* always cover at least the sky */
}

div.fuse-core-content {
    color: white;
}


/* --------- Avatar ---------------------------------------------------------------------------- */

#avatar {
    height: 180px;
    border-radius: 100%;
    border: 5px solid white;
}


/* --------- Title / Subtitle ------------------------------------------------------------------ */

h1 {
    letter-spacing: 0.1em;
    font-size: 3em;
    @media (max-width: 575.98px) {  /* used on bootstrap size 'xs' */
        letter-spacing: 0;
    }
}
h3 {
    font-size: 1.2em;
}


/* --------- The Main Links -------------------------------------------------------------------- */

div.row.links {
    > div {
        white-space: nowrap;
        padding: 0;
    }
    a {
        color: white;
        border-radius: 100%;
        border: 2px solid white;
        display: inline-block;
        font-size: 1.8em;
        line-height: 1em;
        overflow: hidden;
        @media (max-width: 575.98px) {  /* used on bootstrap size 'xs' */
            border-radius: 10px;
            width: 100%;
        }

        &:hover {
            text-decoration: none;
            -webkit-transition: 300ms;
            -moz-transition: 300ms;
            transition: 300ms;
            background-color: rgba(0, 0, 0, 0.5);
        }

        > i {
            display: inline-block;
            margin: 16px 12px;
        }
        > span {
            margin-right: 15px;
        }
    }
}


/* --------- Extra HTML ------------------------------------------------------------------------ */

.fuse-core-extra {
    color: rgba(255, 255, 255, 0.7);

    a {
        color: rgba(255, 255, 255, 0.9);
        border-radius: 3px;
        padding: 0 2px;

        &:hover {
            color: white;
            text-decoration: none;
            background-color: rgba(0, 0, 0, 0.6);
        }
    }
}


/* --------- Footer ---------------------------------------------------------------------------- */

footer {
    position: absolute;
    bottom: 0;
    height: $fc-footer-height;
    line-height: $fc-footer-height - 2 * 10px;
    padding: 10px 0;
    width: 100%;
    font-size: smaller;
    color: rgba(255, 255, 255, 0.4);

    .col {
        padding: 0;
    }
    a {
        color: rgba(255, 255, 255, 0.6);
        text-decoration: none;
        &:hover {
            text-decoration: none;
            color: white;
        }
    }
}
